<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card>
      <div class="py-5 flex space-x-2">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          CP Update Details
        </h3>
        <asom-badge variant="error" v-if="isExpired">Expired</asom-badge>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-8">
          <asom-form-field label="CP Update Number">
            <p class="font-semibold">
              {{ get(itemData, "cpUpdateNo", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Title">
            <p class="font-semibold">
              {{ get(itemData, "title", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Description">
            <p class="font-semibold">
              {{ get(itemData, "description", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Date Created">
            <p class="font-semibold">
              {{ formatDate(get(itemData, "dateCreated", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field label="Created By">
            <p class="font-semibold">
              {{ get(itemData, "createdBy", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Acknowledgement Deadline Date">
            <p class="font-semibold">
              {{
                formatDate(get(itemData, "acknowledgementDeadlineDate", "-"))
              }}
            </p>
          </asom-form-field>
          <asom-form-field label="Expiry Date">
            <p class="font-semibold">
              {{ formatDate(get(itemData, "expiryDate", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="itemData.files.length > 0"
            label="Supporting Document"
          >
            <asom-upload-file-list
              :files="itemData.files"
              disabled-remove
              disabled-download
            />
          </asom-form-field>
          <asom-form-field class="col-span-2" label="Acknowledgement Summary">
            <div class="space-y-2">
              <asom-link @click="navigateToReportPage" class="text-sm"
                >Click here for more details</asom-link
              >
              <asom-client-table
                :columns="[
                  'station',
                  'line',
                  'total',
                  'acknowledged',
                  'pendingAcknowledgement',
                ]"
                :data="stationAcknowledgementList"
              >
              </asom-client-table>
            </div>
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
      </div>
    </asom-card>
  </div>
</template>

<script>
import moment from "moment";
import get from "lodash.get";
import groupBy from "lodash.groupby";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import {
  getCPUpdateDetails,
  getAcknowledgementSummary,
} from "../../../services/opsComms.service";

export default {
  name: "ViewOrder",
  data() {
    return {
      error: null,
      itemData: {
        cpUpdateNo: get(this.$route.params, "cpUpdateNo", null),
        title: get(this.$route.params, "title", null),
        description: get(this.$route.params, "description", null),
        dateCreated: get(this.$route.params, "dateCreated", null),
        createdBy: get(this.$route.params, "createdBy", null),
        acknowledgementDeadlineDate: get(
          this.$route.params,
          "acknowledgementDeadlineDate",
          null
        ),
        expiryDate: get(this.$route.params, "expiryDate", null),
        files: [],
      },
      acknowledgementList: [],
    };
  },
  mounted() {
    this.getDetails();
  },
  computed: {
    isExpired() {
      const expiryDate = moment(this.itemData.expiryDate);
      const today = moment(Date.now());
      return expiryDate.isBefore(today, "day");
    },
    stationAcknowledgementList() {
      if (!this.acknowledgementList) return [];
      const groupByStations = groupBy(
        this.acknowledgementList,
        (item) => item.recipientStationName
      );
      const stationNames = Object.keys(groupByStations).sort();
      return stationNames.map((station) => ({
        station,
        line: get(groupByStations, `${station}.0.recipientLine`),
        total: groupByStations[station].length,
        acknowledged: groupByStations[station].filter(
          (item) => item.isAcknowledged
        ).length,
        pendingAcknowledgement: groupByStations[station].filter(
          (item) => !item.isAcknowledged
        ).length,
      }));
    },
  },
  methods: {
    get,
    formatDate(date) {
      if (date !== "-") return displayUtcDate(date);
      else return date;
    },
    async getDetails() {
      this.error = null;
      const result = await getCPUpdateDetails({
        id: get(this.$route.params, "cpUpdateId"),
      });

      if (result.success) {
        this.setFormData(get(result.payload, "data"));
        const result2 = await getAcknowledgementSummary({
          search: this.itemData.cpUpdateNo,
        });
        if (result2.success) {
          this.acknowledgementList = get(result2.payload, "list", []);
        } else {
          this.error = result2.payload;
          this.$scrollTop();
          return;
        }
      } else {
        this.error = result.payload;
      }
    },
    setFormData(data) {
      this.itemData.cpUpdateNo = get(data, "cpUpdateNo", "-");
      this.itemData.title = get(data, "title", "-");
      this.itemData.description = get(data, "description", "-");
      this.itemData.dateCreated = get(data, "dateCreated", "-");
      this.itemData.createdBy = get(data, "createdBy", "-");
      this.itemData.acknowledgementDeadlineDate = get(
        data,
        "acknowledgementDeadlineDate",
        "-"
      );
      this.itemData.expiryDate = get(data, "expiryDate", "-");
      let attachments = get(data, "attachments", null);
      if (attachments.length > 0) this.itemData.files = attachments;
    },
    navigateToReportPage() {
      this.$router.push({
        name: "CP Update Report",
        query: {
          search: this.itemData.cpUpdateNo,
        },
      });
    },
  },
};
</script>
